.banner {
    position: relative;
    height: calc(100vh - 68.5px);
}
.banner img {
    width: 100%;
    height: calc(100vh - 68.5px);
    object-fit: cover;
    position: absolute;
}
.banner .typing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px #000;
    letter-spacing: 0.1rem;
}